<template>
  <div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>{{ $t('measure') }}</th>
          <th v-if="hasMeasureWithScheduleType">
            {{ $t('schedule_type') }}
          </th>
          <th>{{ $t('frequency') }}</th>
          <th class="due-date">
            {{ $t('dueDate') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ClinicianPatientMeasuresAddedOverviewTableItem
          v-for="(measure, i) in measures"
          :key="i"
          :measure="measure"
          :with-schedule-type="hasMeasureWithScheduleType"
          @triggerDeleteDialog="triggerDeleteDialog"
        />
        <tr v-if="!hasMeasures">
          <td colspan="3">
            {{ $t('allMeasuresRemoved') }}
          </td>
        </tr>
      </tbody>
    </table>
    <delete-dialog
      v-if="deleteDialog"
      :title="$t('areSureRemoveMeasure')"
      :confirm-btn="$t('confirm')"
      @onDelete="deleteMeasure"
      @onCancel="hideDeleteDialog"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/common/DeleteDialog'
import ClinicianPatientMeasuresAddedOverviewTableItem from '@/components/clinician/patients/measures/ClinicianPatientMeasuresAddedOverviewTableItem'
import { DELETE_SCHEDULED_MEASURE } from '@/store/modules/clinicians/constants.js'

export default {
  name: 'ClinicianPatientMeasuresAddedOverviewTable',
  components: { DeleteDialog, ClinicianPatientMeasuresAddedOverviewTableItem },
  props: ['measures', 'hasMeasures'],
  data () {
    return {
      deleteDialog: false,
      measureToDelete: {}
    }
  },
  computed: {
    hasMeasureWithScheduleType () {
      return this.hasMeasures && this.measures.some(m => m.schedule_type)
    }
  },
  methods: {
    deleteMeasure () {
      if (this.lastSessionDetail) {
        this.fetchTeamLastSession({ ...this.lastSessionDetail })
      }
      this.deleteDialog = false
      this.$store.dispatch(DELETE_SCHEDULED_MEASURE, { clientMeasureId: this.measureToDelete.id }).then(() => {
        this.$toast.success({ message: this.$t('measureRemoved') })
      })
    },
    triggerDeleteDialog (measure) {
      this.measureToDelete = measure
      this.deleteDialog = true
    },
    hideDeleteDialog () {
      this.measureToDelete = {}
      this.deleteDialog = false
    }
  }
}
</script>
