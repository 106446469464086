<template>
  <tr
    v-if="!loadingSchedulingOptions"
    class="clinician-patient-measures-added-overview-table-item"
  >
    <td>{{ measure.abbreviation }}</td>
    <td v-if="measure.schedule_type && !measure.clinical_administered">
      {{ capitalizeString(measure.schedule_type) }}
    </td>
    <td v-if="!measure.clinical_administered">
      {{ getFrequencyText }}
    </td>
    <td
      class="due-date"
      :colspan="colspan"
    >
      <box-with-action @wasClicked="emitTriggerDeleteDialog">
        <ClinicalAdministeredButton
          v-if="measure.clinical_administered"
          slot="content"
          :measure="measure"
        />
        <template
          v-else
          slot="content"
        >
          {{ $toLocal(measure.due_date, 'MMM DD, YYYY') }}
        </template>
      </box-with-action>
    </td>
  </tr>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import BoxWithAction from '@/components/common/BoxWithAction'
import ClinicalAdministeredButton from '@/components/clinician/patients/measures/ClinicalAdministeredButton'
import { mapGetters } from 'vuex'

export default {
  name: 'ClinicianPatientMeasuresAddedOverviewTableItem',
  components: { BoxWithAction, ClinicalAdministeredButton },
  mixins: [Helpers, DateTimeHelper],
  props: ['measure', 'withScheduleType'],
  computed: {
    ...mapGetters({
      frequencies: 'getFrequencies',
      schedulingOptions: 'getSchedulingOptions',
      loadingSchedulingOptions: 'getLoadingSchedulingOptions'
    }),
    colspan () {
      return this.measure.clinical_administered ? this.withScheduleType ? '3' : '2' : '1'
    },
    getFrequencyText () {
      let frequencyItem = this.frequencies.find(f => f.value === this.measure.frequency)
      if (this.schedulingOptions && this.schedulingOptions.frequency_options && this.schedulingOptions.frequency_options.length) {
        frequencyItem = this.schedulingOptions.frequency_options.find(f => f.value === this.measure.frequency)
      }
      return frequencyItem ? frequencyItem.text : ''
    }
  },
  methods: {
    emitTriggerDeleteDialog () {
      this.$emit('triggerDeleteDialog', this.measure)
    }
  }

}
</script>

<style lang="scss">
  .clinician-patient-measures-added-overview-table-item {
    .clinical-administered-button {
      width: calc(100% - 85px);
    }
  }
</style>
