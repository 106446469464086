<template>
  <div class="measures-added-overview">
    <div>
      <h3>{{ $tc('addedMeasures', measures.length > 1 ? 2 : 1) }}</h3>
      <h4>{{ activeAssignment.patient_first_name }} {{ activeAssignment.patient_last_name }}</h4>
      <div
        v-if="showProgramsUserIsMember"
        class="user-programs"
      >
        <p class="user-programs-title">
          {{ $t('program') }}
        </p>
        <p
          v-for="assignatinon in activePatientProgramsAssignation"
          :key="assignatinon.id"
          class="user-programs-name"
        >
          {{ assignatinon.program.full_name }}
        </p>
      </div>
      <div
        v-if="patient.patient_relation_id !== 1"
        class="respondent-name"
      >
        {{ patient.first_name }} {{ patient.last_name }} <small> ({{ patient.patient_relation }}) </small>
      </div>
      <ClinicianPatientMeasuresAddedOverviewTable
        :measures="measures"
        :has-measures="hasMeasures"
      />
    </div>
    <div class="buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        @click="$router.go(-1)"
      >
        {{ $t('addAnother') }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeOverview"
      >
        {{ $t('done') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ClinicianPatientMeasuresAddedOverviewTable from '@/components/clinician/patients/measures/ClinicianPatientMeasuresAddedOverviewTable'
import { LastSessionDetail } from '@/mixins/LastSessionDetail'
import { PatientHelper } from '@/mixins/PatientHelper'

export default {
  name: 'ClinicianPatientMeasuresAddedOverview',
  components: {
    ClinicianPatientMeasuresAddedOverviewTable
  },
  mixins: [LastSessionDetail, PatientHelper],
  computed: {
    ...mapGetters({
      measures: 'getMeasureAssignedDuringSession',
      patient: 'getActiveAssignment',
      detailToOpen: 'getDetailToOpen',
      settings: 'generalSettings',
      homeTab: 'getHomeTab'
    }),
    hasMeasures () {
      return !!this.measures.length
    }
  },
  methods: {
    ...mapMutations({
      highlightPatient: 'programs/HIGHLIGHT_PATIENT',
      resetMeasuresAssigned: 'measures/RESET_MEASURES_ASSIGNED_DURING_SESSION'
    }),
    openDetailIfClosed () {
      if (!this.detailOpened) {
        this.openLastSessionDetail(this.detailToOpen)
        this.highlightPatient({ patient: { access_control_id: this.detailToOpen.access_control_id }, programId: this.detailToOpen.program_id })
        this.$store.commit('user/SET_DETAIL_TO_OPEN', null)
      }
    },
    closeOverview () {
      let route = 'ClinicianPatientMeasures'
      if (['ClinicianPatientsByProgramAddMeasureToPatientOverview', 'ClinicianHomePageMeasuresAddedOverview'].includes(this.$route.name)) {
        route = 'ClinicianHomepage'
        const message = this.hasMeasures ? 'measuresAddedToRespondent' : 'respondentNotMeasuresAssigned'
        this.$toast.success({ message: this.$t(message) })
      }
      this.$router.push({ name: route, params: { ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId }, query: { homeTab: this.homeTab } })
      this.resetMeasuresAssigned()
    }
  }

}
</script>
